import { ActivationResult } from './../../../model/activation';
import { Injectable } from '@angular/core';
import { User } from '../../../model/user';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class AuthService {
  public user: User;
  public token$ = new BehaviorSubject<string | null>(null);
  public idToken$ = new BehaviorSubject<string | null>(null);
  private userLoading = false;

  abstract registerUser(user): Observable<unknown>;
  abstract registerPortalUser(portalUser): Observable<unknown>;
  abstract verifyToken(): Observable<unknown>;
  abstract loginUser(email, adb2cJwtToken): Observable<User>;
  abstract checkActiveAccount(): Observable<unknown>;
  abstract logout();
  abstract activateMRO(email, adb2cJwtToken): Observable<unknown>;

  abstract validateActivationCode(activationCode: string, email: string): Observable<ActivationResult>;

  loggedIn() {
    return !!sessionStorage.getItem('token') && !!sessionStorage.getItem('user');
  }

  getToken() {
    return sessionStorage.getItem('token');
  }

  getUser() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    return this.user;
  }

  setUser(user: User) {
    this.user = user;
  }


  checkIfAdminLoggedIn(){
    this.user = JSON.parse(sessionStorage.getItem('user'));
    // if(this.user.roleId === environment.ROLE_ADMIN){
    //   return true;
    // }
    return false;
  }

  getLoggedInUserRegistrationStatus(){
    this.user = JSON.parse(sessionStorage.getItem('user'));
    if(this.user.registrationStatus === 1){
      return true;
    }
    return false;
  }

  refreshUser(): boolean {
    if (this.loggedIn()) {
      this.userLoading = true;
      this.checkActiveAccount().subscribe(() => {
        this.userLoading = false;
      });

      return true;
    } else {
      return false;
    }
  }

  isJWTTokenExpired(token: string): boolean {
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return tokenData.exp < currentTimestamp;
  }

  setToken(token: string | null) {
    this.token$.next(token);
  }

  setIdToken(idToken: string | null) {
    this.idToken$.next(idToken);
  }

}

