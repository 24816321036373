<div *ngIf="loading">
  <app-spinner [sectionType]="'vgraph'"></app-spinner>
</div>
<div style="display: flex; justify-content: center; position: relative">
  <div *ngIf="!loading && dataValid" [id]="chartId">
    <ngx-charts-bar-vertical
      [animations]="animations"
      [view]="isMobile ? [400, 300] : [500, 300]"
      [showGridLines]="showGridLines"
      [roundDomains]="roundDomains"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [results]="graphAndBarChartData"
      [legendTitle]="getLegendTitle(barChartData)"
      [legendPosition]="'below'"
      [tooltipDisabled]="false"
      [xAxis]="showXAxis"
      [yAxisTickFormatting]="formatDollar"
      [yAxis]="showYAxis"
    >
      <ng-template #tooltipTemplate let-model="model">
        <div style="text-align: left">
          <span> {{ model.name }} ·</span>
          <span> {{ model.value | currency }}</span>
        </div>
      </ng-template>

      <ng-template #seriesTooltipTemplate let-model="model">
        <div style="text-align: left" *ngFor="let entry of model">
          <span class="small-box" [style.background-color]="entry.color"></span>
          <span> {{ entry.name }} ·</span>
          <span> {{ entry.value | currency }}</span>
        </div>
      </ng-template>
    </ngx-charts-bar-vertical>
  </div>
  <ng-container *ngIf="!loading && !dataValid">
    <app-no-data></app-no-data>
  </ng-container>
</div>
