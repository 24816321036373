import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { MockAuthService } from './services/auth/mock-auth.service';
import { HttpAuthService } from './services/auth/http-auth.service';
import { AuthService } from './services/auth/auth.service';
import { MarketService } from './services/market/market.service';
import { MockMarketService } from './services/market/mock-market.service';
import { HttpMarketService } from './services/market/http-market.service';
import { MockPerformanceService } from './services/performance/mock-performance.service';
import { HttpPerformanceService } from './services/performance/http-performance.service';
import { PerformanceService } from './services/performance/performance.service';
import { PaymentsService } from './services/payments/payments.service';
import { MockPaymentsService } from './services/payments/mock-payments.service';
import { HttpPaymentsService } from './services/payments/http-payments.service';
import { LookupService } from './services/lookup/lookup.service';
import { MockLookupService } from './services/lookup/mock-lookup.service';
import { HttpLookupService } from './services/lookup/http-lookup.service';
import { MockPropertyService } from './services/property/mock-property.service';
import { PropertyService } from './services/property/property.service';
import { HttpPropertyService } from './services/property/http-property.service';
import { RecentActivityService } from './services/recent-activity/recent-activity.service';
import { MockRecentActivityService } from './services/recent-activity/mock-recent-activity.service';
import { HttpRecentActivityService } from './services/recent-activity/http-recent-activity.service';
import { HttpProfileService } from './services/profile/http-profile.service';
import { MockProfileService } from './services/profile/mock-profile.service';
import { ProfileService } from './services/profile/profile.service';
import { PartnerService } from './services/partner/partner.service';
import { MockPartnerService } from './services/partner/mock-partner.service';
import { HttpPartnerService } from './services/partner/http-partner.service';
import { ProductService } from './services/products/product.service';
import { MockProductService } from './services/products/mock-product.service';
import { HttpProductService } from './services/products/http-product.service';
import { OperatorService } from './services/operator/operator.service';
import { MockOperatorService } from './services/operator/mock-operator.service';
import { HttpOperatorService } from './services/operator/http-operator.service';

@NgModule({
  declarations: [],
  imports: environment.mock ? [
    CommonModule
  ] : [
    CommonModule
  ],
  providers: environment.mock ? [
    {provide: AuthService, useClass: MockAuthService},
    {provide: MarketService, useClass: MockMarketService},
    {provide: PerformanceService, useClass: MockPerformanceService},
    {provide: PaymentsService, useClass: MockPaymentsService},
    {provide: LookupService, useClass: MockLookupService},
    {provide: PropertyService, useClass: MockPropertyService},
    {provide: RecentActivityService, useClass: MockRecentActivityService},
    {provide: ProfileService, useClass: MockProfileService},
    {provide: PartnerService, useClass: MockPartnerService},
    {provide: ProductService, useClass: MockProductService},
    {provide: OperatorService, useClass: MockOperatorService}
  ] : [
    {provide: AuthService, useClass: HttpAuthService},
    {provide: MarketService, useClass: HttpMarketService},
    {provide: PerformanceService, useClass: HttpPerformanceService},
    {provide: PaymentsService, useClass: HttpPaymentsService},
    {provide: LookupService, useClass: HttpLookupService},
    {provide: PropertyService, useClass: HttpPropertyService},
    {provide: RecentActivityService, useClass: HttpRecentActivityService},
    {provide: ProfileService, useClass: HttpProfileService},
    {provide: PartnerService, useClass: HttpPartnerService},
    {provide: ProductService, useClass: HttpProductService},
    {provide: OperatorService, useClass: HttpOperatorService}
  ]

})
export class CoreModule { }
