<div id="mro_content">
  <div class="mobile_container">
    <div id="mro_recent_payments_inner">
      <h1 class="mro_title">Contact Us</h1>
      <div class="intro_text">
        <div class="fine_print">
        </div>
      </div>

      <div class="space_divider extra_padding"></div>

      <h2 class="mro_title">Ask a Question</h2>
      <div class="intro_text">
        <div class="fine_print">
          Our wealth managers will do our best to answer your questions.
        </div>
      </div>
      <div class="mro_content_inner">
        <div class="form_row">
          <div class="form_label">
            <label for="question">Enter your question here:</label>
          </div>
          <div class="form_input">
            <textarea
              [(ngModel)]="contactMessage"
              placeholder="Enter your message here"
              class="inputbox"
              id="question"
              name="question"
              style="height: 500px"
              (focus)="setCursorPosition($event)"
            ></textarea>
          </div>
        </div>

        <div class="mro_actions full">
          <div class="mro_actions_left">
            <ul class="options_list">
              <li></li>

              <li></li>
            </ul>
          </div>
          <div class="mro_actions_right">
            <ul class="options_list">
              <li>
                <button
                  type="button"
                  class="btn_normal btn_purple btn_big btn_wide"
                  (click)="sendContactUs()"
                >
                  <span class="btn_text">Submit</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-modal *ngIf="messageSent && !messageError" [modalTitle]="'Message Sent'">
    <div class="modal-container">
      <div class="modal-header">
        <h4 class="modal-title">Message Sent</h4>
      </div>
      <div class="modal-body">
        <p>Your message has been sent successfully.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
      </div>
    </div>
  </app-modal>
  <app-modal *ngIf="messageError" [modalTitle]="'Error'">
    <div class="modal-container">
      <div class="modal-header">
        <h4 class="modal-title">No Message Sent</h4>
      </div>
      <div class="modal-body">
        <p>{{ errorMessage }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
      </div>
    </div>
  </app-modal>


</div>

