<div id="content">
  <div id="pathway">
    <div class="page_container">
      <div id="pathway_inner">
        <div class="pathway_left">
          <ul>
            <li>
              <div class="pathway">
                <ul>
                  <li><span>Payments</span></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="pathway_right">
          <ul>
            <li>
              <form [formGroup]="paymentForm">
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="page_search">Search</label>
                  </div>
                  <div class="form_input">
                    <input
                      type="text"
                      class="inputbox searchbox padding_medium no_border"
                      id="page_search"
                      name="page_search"
                      placeholder="Search client’s names, property, lease, wells..."
                      style="width: 360px"
                      formControlName="searchStr"
                    />
                  </div>
                </div>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="page_container">
    <div id="content_inner">
      <div class="content_action_bar">
        <div class="action_bar_left">
          <ul>
            <li>
              <form [formGroup]="paymentForm">
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="operator_list">Operators</label>
                  </div>
                  <div class="form_input">
                    <select
                      #operator_list
                      name="operator_list"
                      id="operator_list"
                      class="inputbox inputbox_filter"
                      formControlName="operator"
                      (change)="onOperatorChange($event.target.value)"
                    >
                      <option value="0">All Operators</option>
                      <option
                        *ngFor="
                          let operator of operators;
                          trackBy: trackByOperatorId
                        "
                        [value]="operator.id"
                      >
                        {{ operator.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </li>
          </ul>
        </div>
        <div class="action_bar_right">
          <form [formGroup]="paymentForm">
            <div class="page_selector">
              <label for="page_size">Results Per Page:</label>
              <select
                name="page_size"
                id="page_size"
                class="inputbox inputbox_filter"
                formControlName="pageSize"
                (change)="onPageSizeChange()"
                #pageSizeSelect
              >
                <option value="12">12</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="content_body">
        <div class="scroll_container" style="height: 30rem">
          <table class="table_grid no_wrap">
            <thead>
              <tr>
                <th>
                  <div class="table_header">Payment Date</div>
                </th>
                <th>
                  <div class="table_header">Operator</div>
                </th>
                <th>
                  <div class="table_header">Payment Status</div>
                </th>
                <th>
                  <div class="table_header">Payment Type</div>
                </th>
                <th>
                  <div class="table_header">Check #</div>
                </th>
                <th>
                  <div class="table_header">No. Transactions</div>
                </th>
                <th>
                  <div class="table_header">Amount</div>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="!loading$">
              <tr
                [ngClass]="{ pointer: payment.royaltyPayments.length > 0 }"
                *ngFor="let payment of payments | async; let i = index"
              >
                <td
                  matTooltip="{{
                    payment?.rayaltyPayments?.length == 0
                      ? 'Pending'
                      : (payment?.paymentDate | date : 'dd MMM yyyy')
                  }}"
                  *ngIf="
                    operatorId.getValue() == 0 ||
                    payment.operator.id == operatorId.getValue()
                  "
                  (click)="
                    payment.royaltyPayments.length > 0 &&
                      navigateToPaymentDetails(payment)
                  "
                >
                  {{ payment?.paymentDate | date : "dd MMM yyyy" }}
                </td>
                <td
                  matTooltip="{{
                    payment?.rayaltyPayments?.length == 0
                      ? 'Pending'
                      : payment?.operator?.name
                  }}"
                  *ngIf="
                    operatorId.getValue() == 0 ||
                    payment.operator.id == operatorId.getValue()
                  "
                  (click)="
                    payment.royaltyPayments.length > 0 &&
                      navigateToPaymentDetails(payment)
                  "
                >
                  {{ payment?.operator?.name }}
                </td>
                <td
                  matTooltip="{{
                    payment?.rayaltyPayments?.length == 0
                      ? 'Pending'
                      : payment?.status?.name
                  }}"
                  *ngIf="
                    operatorId.getValue() == 0 ||
                    payment.operator.id == operatorId.getValue()
                  "
                  (click)="
                    payment.royaltyPayments.length > 0 &&
                      navigateToPaymentDetails(payment)
                  "
                >
                  {{ payment?.status?.name }}
                </td>
                <td
                  matTooltip="{{
                    payment?.rayaltyPayments?.length == 0
                      ? 'Pending'
                      : payment?.paymentType?.name
                  }}"
                  *ngIf="
                    operatorId.getValue() == 0 ||
                    payment.operator.id == operatorId.getValue()
                  "
                  (click)="
                    payment.royaltyPayments.length > 0 &&
                      navigateToPaymentDetails(payment)
                  "
                >
                  {{ payment?.paymentType?.name }}
                </td>
                <td
                  matTooltip="{{
                    payment?.rayaltyPayments?.length == 0
                      ? 'Pending'
                      : payment?.checkNo
                  }}"
                  align="center"
                  *ngIf="
                    operatorId.getValue() == 0 ||
                    payment.operator.id == operatorId.getValue()
                  "
                  (click)="
                    payment.royaltyPayments.length > 0 &&
                      navigateToPaymentDetails(payment)
                  "
                >
                  {{ payment?.checkNo }}
                </td>
                <td
                  matTooltip="{{
                    payment?.rayaltyPayments?.length == 0
                      ? 'Pending'
                      : payment?.royaltyPayments?.length
                  }}"
                  align="center"
                  *ngIf="
                    operatorId.getValue() == 0 ||
                    payment.operator.id == operatorId.getValue()
                  "
                >
                  {{ payment?.royaltyPayments?.length }}
                </td>
                <td
                  matTooltip="{{
                    payment?.rayaltyPayments?.length == 0
                      ? 'Pending'
                      : (payment?.checkAmount | currency)
                  }}"
                  align="right"
                  *ngIf="
                    operatorId.getValue() == 0 ||
                    payment.operator.id == operatorId.getValue()
                  "
                  (click)="
                    payment.royaltyPayments.length > 0 &&
                      navigateToPaymentDetails(payment)
                  "
                >
                  {{ payment?.checkAmount | currency }}
                </td>
              </tr>
            </tbody>
          </table>
          <app-spinner [sectionType]="'payments'" *ngIf="loading$"></app-spinner>
        </div>
      </div>
      <!-- Body Footer -->
      <div class="content_footer">
        <div class="content_footer_inner">
          <div class="content_footer_left">
            <ul>
              <li>
                <button
                  type="button"
                  class="btn_normal btn_white"
                  id="btn_back"
                  (click)="onBack()"
                >
                  <span class="btn_icon"><span class="icon_back"></span></span>
                  &nbsp;<span class="btn_text">Back</span>
                </button>
              </li>
            </ul>
          </div>
          <div class="content_footer_right">
            <ul>
              <li>
                <button
                    type="button"
                    class="btn_normal btn_blue"
                    id="btn_export_csv_properties"
                    (click)="exportToCSV()"
                  >
                  <span class="btn_icon"> <img src="../assets/images/download_icon.svg" alt="Download Icon"> </span>
                    &nbsp;<span class="btn_text">CSV</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content_pagination flex_right_single_line">
        <div class="pagination_buttons">
          <button
            class="btn_pagination"
            (click)="goToFirstPage()"
            [disabled]="currentPage === 1"
          >
            <<
          </button>
          <button
            class="btn_pagination"
            (click)="goToPreviousPage()"
            [disabled]="currentPage === 1"
          >
            <
          </button>
          <div class="pagination_info">
            <span>Page {{ currentPage }} of {{ totalPages || 0 }}</span>
          </div>
          <button
            class="btn_pagination"
            (click)="goToNextPage()"
            [disabled]="currentPage === totalPages"
          >
            >
          </button>
          <button
            class="btn_pagination"
            (click)="goToLastPage()"
            [disabled]="currentPage === totalPages"
          >
            >>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal *ngIf="modalOpen">
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">CSV Processing</h4>
      <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Your CSV is currently processing in the background! It will continue to process and download even if you navigate away from this page.</p>
    </div>
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button> -->
    </div>
  </div>
