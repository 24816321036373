<div id="card-container">
  <div class="card-section">
    <div class="card-logo">
      <img alt="logo" class="logo_image" src="../../../assets/images/Pipelinedl-ogts_logo.png">
    </div>
    <div class="card-title">
      Welcome to the MRO Portal,
      <span *ngIf="!isStatusChecked">please wait while the your activation link is checked for validity</span>
      <app-spinner *ngIf="!isStatusChecked" [sectionType]="'home'" [width]="'100px'" [height]="'100px'"></app-spinner>
      <span *ngIf="isStatusChecked && !isActivationCodeValid">your activation link is not valid.</span>
      <div *ngIf="isStatusChecked && !isActivationCodeValid">Please click the button below to return to the login page.</div>
      <span *ngIf="isStatusChecked && isActivationCodeValid">your activation link is valid.</span>
      <div *ngIf="isStatusChecked && isActivationCodeValid">Please use the popup to activate your account.</div>
    </div>
    <div class="card-content" *ngIf="!isLoggedIn && !isActivationCodeValid && isStatusChecked">
      <button class="adb2c-loginbutton" (click)="goToLogin()">Go To Login</button>
    </div>
  </div>
</div>
