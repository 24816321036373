/* eslint-disable @typescript-eslint/no-explicit-any */
import { PreloadPaginationService } from './../../core/services/preloadPagination/preload-pagination.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { PaymentSearch } from '../../model/payment_search';
import { CommonModule, DatePipe, DecimalPipe, formatDate, Location } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, map, BehaviorSubject, combineLatest, switchMap, of } from 'rxjs';
import { Router } from '@angular/router';
import { SpinnerComponent } from '../spinner/spinner.component';
import { PayingOperator } from '../../model/operator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Payment } from '../../model/payments';
import { PaymentsService } from '../../core/services/payments/payments.service';
import { StorageService } from '../../core/services/storage/storage.service';
import { OperatorService } from '../../core/services/operator/operator.service';
import { ModalModule } from "../modal/modal.module";

@Component({
  selector: 'app-payments',
  standalone: true,
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  providers: [DatePipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerComponent,
    MatTooltipModule,
    ModalModule,
    DecimalPipe
]
})
export class PaymentsComponent implements OnInit {
  @ViewChild('pageSizeSelect', { static: true }) pageSizeSelect: any;

  public paymentForm: UntypedFormGroup = this.fb.group({
    operator: 0,
    month: this.datePipe.transform(new Date(), 'yyyyMM'),
    searchStr: [''],
    pageSize: 12
  });

  public paymentSearch: PaymentSearch = {
    operatorId: 0,
    month: parseInt(this.datePipe.transform(new Date(), 'yyyyMM')),
    date: new Date()
  };

  public operatorId = new BehaviorSubject<number>(0);
  public pageNo = new BehaviorSubject<number>(0);
  public payments: Observable<Payment[]>;
  public allPayments: Payment[] = [];
  public allOperatorPayments: Payment[] = [];
  public selectedOperator: number = 0;
  public paginatedPayments: Observable<Payment[]>;
  public allPaginatedPayments: Observable<Payment[]>;
  public pageLoading: boolean = false;
  public paymentDate: Date;
  public lastPaymentMonth: number;
  public currentPage: number = 1;
  public currentPageAll: number = 1;
  public currentPageOperator: number = 1;
  public operators: PayingOperator[] = [];
  public modalOpen: boolean = false;
  public csvProcessing: boolean = false;
  public loading$: boolean;

  private pageSize: number = 12;
  private lastOperatorId: number = 0;
  private unsubscribe = new Subject<void>();

  constructor(
    @Inject(UntypedFormBuilder)
    private fb: UntypedFormBuilder,
    private paymentService: PaymentsService,
    private datePipe: DatePipe,
    private router: Router,
    private location: Location,
    private storageService: StorageService,
    private preloadPaginationService: PreloadPaginationService,
    private operatorService: OperatorService
  ) {
    this.paymentSearch.month = this.paymentForm.get('month').value;
    this.paymentSearch.operatorId = 0;
    this.payments = combineLatest([this.operatorId, this.pageNo]).pipe(
      map(([currentOperatorId, currentPageNo]) => {
        const paymentsObservable = currentOperatorId == 0 ? this.storageService.paymentsByPage : this.storageService._paymentsByOperatorByPage;
        this.preloadPaginationService.setCurrentOperatorIdManager(currentOperatorId);
        if (paymentsObservable && typeof paymentsObservable.asObservable === 'function') {
          return paymentsObservable.asObservable().pipe(
            map(paymentsArray => paymentsArray.length > 0 ? paymentsArray[currentPageNo] : null)
          );
        } else {
          console.error('paymentsObservable is undefined or does not have asObservable method');
          return of(null);
        }
      }),
      switchMap(observable => observable)
    );
  }

  ngOnInit() {
    this.getPaginatedPayments();
    this.setTotalRecords();
    this.getOperators();
    this.preloadPaginationService.loading$.subscribe(
      (loading) => {
        this.loading$ = loading;
      }
    );
    this.preloadPaginationService.CSVProcessing$.subscribe(
      (modalOpen) => {
        this.modalOpen = modalOpen;
        if (!modalOpen && this.csvProcessing) {
          this.csvProcessing = false;
        }
      }
    );
  }

  get totalPages(): number {
    if (this.operatorId.getValue() == 0) {
      return Math.ceil((this.storageService.getPaymentNoOfRecords() + this.pageSize) / this.pageSize);
    } else {
      return Math.ceil(this.preloadPaginationService.getTotalPagesOperator()) + 1;
    }
  }

  get recordsAvailable(): boolean {
    return this.allPayments && this.allPayments.length > 0;
  }

  get selectedOperatorName(): string {
    if (this.selectedOperator == 0) {
      return 'All Operators';
    }
    const selectedOperator = this.operators.find(operator => operator.id === this.selectedOperator);
    return selectedOperator ? selectedOperator.name : 'Operator not found';
  }

  private getOperators() {
    this.operatorService.getMroPayingOperators().subscribe(operators => {
      this.operators = operators;
    });
  }

  public closeModal() {
    this.modalOpen = false;
  }

  public navigateToPaymentDetails(payment: Payment) {
    this.storageService.setCurrentPayment(payment);
    this.payments.subscribe((res) => { this.allPayments = res });
    this.paymentService.setPayments(this.allPayments);
    const formattedDate = formatDate(payment.paymentDate, 'yyyyMM', 'en-US');
    this.router.navigate(['/statement/' + formattedDate + '/' + payment.id]);
  }

  public trackByOperatorId(index: number, item: any) {
    return item.id;
  }

  public trackByPageSize(index: number, item: any) {
    return item;
  }

  public trackByPageNo(index: number, item: any) {
    return item;
  }

  public onOperatorChange(operatorId: number) {
    this.operatorId.next(operatorId);
    this.pageNo.next(0);
    if (operatorId != 0) {
        this.storageService.resetPayments();
        this.lastOperatorId = operatorId;
        this.getOperatorPaginatedPayments();
        this.currentPage = 1;
        this.currentPageOperator = 1;
        this.setTotalRecordsByOperator();
    }
  }

  public onBack() {
    this.location.back();
  }

  public onPageSizeChange() {
    this.pageSize = this.pageSizeSelect.nativeElement.value;
    this.pageNo.next(0);
    if (this.operatorId.getValue() == 0) {
      this.getPaginatedPayments();
    } else {
      this.getOperatorPaginatedPayments();
    }
    this.currentPage = 1;
    this.currentPageOperator = 1;
    this.currentPageAll = 1;
    this.setTotalRecords();
    if (this.operatorId.getValue() == 0) {
      this.setTotalRecords();
    } else {
      this.setTotalRecordsByOperator();
    }
  }

  public goToFirstPage() {
    this.currentPage = 1;
    this.pageNo.next(0);
  }

  public goToLastPage() {
    this.currentPage = this.totalPages;
    this.pageNo.next(this.totalPages - 1);
    if (this.operatorId.getValue() == 0) {
      this.currentPageAll = this.currentPage;
    } else {
      this.currentPageOperator = this.currentPage;
    }
  }

  public goToNextPage() {
    this.pageNo.next(this.pageNo.getValue() + 1);
    this.currentPage = this.pageNo.getValue() + 1;
    if (this.operatorId.getValue() != 0) {
      if (this.operatorId.getValue() === this.lastOperatorId) {
        if (!this.storageService.getPaymentsByOperatorByPage(this.pageNo.getValue() + 2)) {
          this.preloadPaginationService.callPaymentsByPageByOperator(this.pageNo.getValue() + 2)
        }
      } else {
        this.getOperatorPaginatedPayments();
      }
    } else {
      if (!this.storageService.getPaymentsByPage(this.pageNo.getValue() + 2)) {
        this.preloadPaginationService.callPaymentsByPage(this.pageNo.getValue() + 2)
      }
    }
  }

  public goToPreviousPage() {
    if (this.currentPage > 1) {
      this.pageNo.next(this.pageNo.getValue() - 1);
      this.currentPage = this.pageNo.getValue() + 1;
      if (this.operatorId.getValue() != 0) {
        if (!this.storageService.getPaymentsByOperatorByPage(this.pageNo.getValue() - 1)) {
          this.preloadPaginationService.callPaymentsByPageByOperator(this.pageNo.getValue() - 1)
        } else {
          this.getOperatorPaginatedPayments();
        }
      } else {
        if (!this.storageService.getPaymentsByPage(this.pageNo.getValue())) {
          this.preloadPaginationService.callPaymentsByPage(this.pageNo.getValue())
        }
      }
    }
  }

  private setTotalRecords() {
    this.paymentService.getMroPaymentsMoreRecords(0, this.pageSize).subscribe((totalRecords) => {
      this.storageService.setPaymentNoOfRecords(totalRecords.noRecords);
    })
  }

  private setTotalRecordsByOperator() {
    this.paymentService.getMroPaymentsByOperatorMoreRecords(this.operatorId.getValue(), 0, this.pageSize).subscribe((totalRecords) => {
      this.storageService.setPaymentsByOperatorNoOfRecords(totalRecords.noRecords);
    })
  }

  public exportToCSV() {
    this.modalOpen = true;
    this.csvProcessing = true;
    this.getAllPaymentsInCurrentContext();
  }

  private getAllPaymentsInCurrentContext() {
    this.preloadPaginationService.callForCsv(this.operatorId.getValue());
  }

  private getPaginatedPayments() {
    this.preloadPaginationService.initialCallPayments(this.pageSize);
  }

  private getOperatorPaginatedPayments() {
    this.preloadPaginationService.initialCallOperatorPayments(this.pageSize, this.operatorId.getValue())
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
