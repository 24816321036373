import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Payment, RoyaltyPayment } from '../../model/payments';
import { extractDeductionObjects } from '../../utilities/extractHeadings';
import { format } from 'date-fns';

@Component({
  selector: 'app-statement-view',
  templateUrl: './statement-view.component.html',
  styleUrl: './statement-view.component.css',
  providers: [DatePipe]
})
export class StatementViewComponent implements OnInit{
  @Input() statement: Payment;
  @Input() statements: Payment[];

  public currSubStatementIndex = 0;
  public royaltyPayments: RoyaltyPayment[];
  public deductionHeaders: string[];
  public salesDeductionHeaders: string[];

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.statement) {
      this.router.navigate(['/']);
    }
    this.royaltyPayments = this.statement?.royaltyPayments;
    if (this.royaltyPayments[0] != undefined  && this.royaltyPayments.length >= 0 && this.royaltyPayments[0].deductionObjects.length >= 1) {
      this.deductionHeaders = extractDeductionObjects(this.statement.royaltyPayments[0].deductionObjects);
    }
    if (this.royaltyPayments[0] != undefined  && this.royaltyPayments.length >= 0 && this.royaltyPayments[0].sale.deductionObjects.length >= 1) {
      this.salesDeductionHeaders = extractDeductionObjects(this.statement.royaltyPayments[0].sale.deductionObjects);
    }
  }

  convertToDate(dateString: string): Date {
    if (dateString === null) {
      return null;
    } else {
      const year = Number(dateString.substr(0, 4));
      const month = Number(dateString.substr(4, 2)) - 1;
      return new Date(year, month);
    }

  }

  public getUtcDateString(date: Date): string {
    return format(date, 'MMM dd, yyyy');
  }


}
