import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { DivisionordersComponent } from './components/divisionorders/divisionorders.component';
import { ContactUsComponent } from './components/contactUs/contact-us.component';
import { ProfileComponent } from './components/profile/profile.component';
import { StatementComponent } from './components/statement/statement.component';
import { DivisionorderDetailsComponent } from './components/divisionorder-details/divisionorder-details.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { RegistrationComponent } from './components/registration/registration.component';
import { AdminDashboardComponent } from './components/dashboard/admin-dashboard/admin-dashboard.component';
import { PropertyDetailComponent } from './components/property-detail/property-detail.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { LoggedOutComponent } from './components/logged-out/logged-out.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { ActivationComponent } from './components/activation/activation.component';

const routes: Routes = [
  { path: 'activation', component: ActivationComponent },
  { path: 'login', component: LoginComponent },
  { path: 'loggedOut', component: LoggedOutComponent },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'properties', component: PropertiesComponent, canActivate: [AuthGuard]},
  { path: 'propertyDetail/:id', component: PropertyDetailComponent, canActivate: [AuthGuard]},
  { path: 'divisionOrders', component: DivisionordersComponent, canActivate: [AuthGuard] },
  { path: 'divisionOrderDetails', component: DivisionorderDetailsComponent, canActivate: [AuthGuard] },
  { path: 'contactUs', component: ContactUsComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'payments', component: PaymentsComponent, canActivate: [AuthGuard] },
  { path: 'statement/:date/:id', component: StatementComponent, canActivate: [AuthGuard] },
  { path: 'auth', component: MsalRedirectComponent },
  { path: 'regauth', component: RegistrationComponent },
  { path: 'adminDashboard', component: AdminDashboardComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
