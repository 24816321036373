<div *ngIf="loading">
  <app-spinner></app-spinner>
</div>
<div style="display: block" *ngIf="!loading">
  <div *ngIf="dataValid" [id]="chartId">
    <ngx-charts-area-chart-stacked
    [results]="chartData"
    [xAxis]="true"
    [view]="isMobile ? [400, 300] : [500, 300]"
    [yAxis]="true"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [tooltipDisabled]="false"
    [seriesTooltipTemplate]="tooltipTemplate"
    [yAxisTickFormatting]="formatDollar"
    yAxisLabel="Revenue"
  >
    <ng-template #tooltipTemplate let-model="model">
      <div style="text-align: left">
        <span> {{ model.series }} ·</span>
        <span> {{ model.name }} ·</span>
        <span> {{ model.value | currency }}</span>
      </div>
    </ng-template>

    <ng-template #seriesTooltipTemplate let-model="model">
      <div style="text-align: left" *ngFor="let entry of model">
        <span class="small-box" [style.background-color]="entry.color"></span>
        <span> {{ entry.series }} ·</span>
        <span> {{ entry.name }} ·</span>
        <span> {{ entry.value | currency }}</span>
      </div>
    </ng-template>
  </ngx-charts-area-chart-stacked>
</div>


  <ng-container *ngIf="!dataValid">
    <app-no-data></app-no-data>
  </ng-container>
</div>
