import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logged-out',
  standalone: true,
  imports: [],
  templateUrl: './logged-out.component.html',
  styleUrl: './logged-out.component.css'
})
export class LoggedOutComponent implements OnInit {

    constructor(private msalService: MsalService) { }

    ngOnInit(): void {
      const accounts = this.msalService.instance.getAllAccounts();
      if (accounts.length === 0) {
        console.info('User is logged out');
      } else {
        console.info('User is still logged in', accounts);
      }
    }

}
