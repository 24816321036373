import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MoreRecords, Payment, PaymentLoadStatus } from '../../../model/payments';
import { PaymentSummary, PaymentSummaryByPeriodSeriesData, PaymentSummaryData, PaymentCount } from '../../../model/payment-summary';
import { environment } from '../../../../environments/environment';
import { payments } from '../../../mockDB/payments';

@Injectable({
  providedIn: 'root'
})
export abstract class PaymentsService {

  private payments: Payment[] = [];

  public payments$ = new BehaviorSubject<Payment[]>([]);

  private recentPayments: Payment[] = [];

  private paymentLoadStatus: PaymentLoadStatus = PaymentLoadStatus.NONE;

  private recentPaymentLoadStatus: PaymentLoadStatus = PaymentLoadStatus.NONE;

  private currentPayment: Payment;

  private isMock: boolean = environment.mock;

  abstract getMroPayments(limit: number): Observable<Payment[]>;

  abstract getMroPaymentSummaryByProperty(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PaymentSummaryData[]>;

  abstract getMroPaymentSummaryByProduct(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PaymentSummaryData[]>;

  abstract getMroPaymentSummaryByPeriod(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummary>;

  abstract getMroPaymentSummaryByPeriodByProperty(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummaryByPeriodSeriesData[]>;

  abstract getMroPaymentSummaryByPeriodByProduct(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummaryByPeriodSeriesData[]>;

  abstract getMroPendingPaymentCount():
    Observable<PaymentCount>;

  abstract getMroPaymentsByPage(pageNo: number, pageSize: number): Observable<Payment[]>;

  abstract getMroPaymentsMoreRecords(pageNo: number, pageSize: number): Observable<MoreRecords>;

  abstract getMroPaymentsByOperatorByPage(operatorId: number, pageNo: number, pageSize: number): Observable<Payment[]>;

  abstract getMroPaymentsByOperatorMoreRecords(operatorId: number, pageNo: number, pageSize: number): Observable<MoreRecords>;


  getPayments(): Payment[] {
    if (this.isMock) {
      return payments
    }
    return this.payments;
  }

  setPayments(payments: Payment[]): void {
    this.payments = payments;
    this.setPayments$(payments);
  }

  setPayments$(payments: Payment[]): void {
    this.payments$.next(payments);
  }

  getCurrentPayment(): Payment {
    return this.currentPayment;
  }

  setCurrentPayment(payment: Payment): void {
    this.currentPayment = payment;
  }

  getPaymentLoadStatus(): PaymentLoadStatus {
    return this.paymentLoadStatus;
  }

  setPaymentLoadStatus(status: PaymentLoadStatus): void {
    this.paymentLoadStatus = status;
  }

  getRecentPayments(): Payment[] {
    return this.recentPayments;
  }

  setRecentPayments(payments: Payment[]): void {
    this.recentPayments = payments;
  }

  getRecentPaymentLoadStatus(): PaymentLoadStatus {
    return this.recentPaymentLoadStatus;
  }

  setRecentPaymentLoadStatus(status: PaymentLoadStatus): void {
    this.recentPaymentLoadStatus = status;
  }

}
