import { AuthenticationResult } from '@azure/msal-browser';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { AzureAuthService } from '../../modules/azure-auth/services/azure-auth/azure-auth.service';
import { catchError, finalize, Observable, of, Subject, takeUntil } from 'rxjs';
import { User } from '../../model/user';
import { HttpService } from '../../http.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrl: './activation.component.css'
})
export class ActivationComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private activationCode: string;

  public errorMessage = '';
  private email: string;
  public isLoggedIn: boolean = false;
  public isActivationCodeValid: boolean = false;
  public isStatusChecked: boolean = false;

  public activationUserData: User = {
    email: '',
    fname: '',
    lname: '',
  }


  constructor(
    private msalService: MsalService,
    private authService: AuthService,
    private httpService: HttpService,
    private azureAuthService: AzureAuthService,
    private router: Router
  ) {
    const href = window.location.href;
    const url = new URL(href);
    this.activationCode = url.searchParams.get('activationCode');
    this.email = url.searchParams.get('email');
  }

  ngOnInit(): void {
    this.isLoggedIn = sessionStorage.getItem('token') !== null;
    this.msalService.initialize().toPromise().catch((error: unknown) => {
      console.error("Error initializing MSAL:", error);
    });
    this.msalService.instance.clearCache();
    const urlParams = new URLSearchParams(window.location.search);
    this.activationCode = urlParams.get('activationCode');
    this.email = urlParams.get('email');
    this.checkIsValidActivationCode();
  }

  activateAccount(): void {
    this.azureAuthService.activateAccount(this.email).pipe(
      takeUntil(this.unsubscribe$),
      catchError((error: Observable<User>) => {
        this.errorMessage = 'Error activating account';
        console.error('Error activating account:', error);
        return of(null);
      })
    ).subscribe((activationResult: AuthenticationResult) => {
      console.log('activationResult:', activationResult);
      if (activationResult) {
        this.authService.setToken(activationResult.idToken);
        this.activationUserData.email = activationResult.account.username;
        sessionStorage.setItem('userData', JSON.stringify(activationResult));
        this.loginActivatedUser();
      }
    });
  }

  private loginActivatedUser(): void {
    const activationResult = JSON.parse(sessionStorage.getItem("userData")) as AuthenticationResult;
    this.authService.activateMRO(this.email, activationResult.idToken).pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => {
        this.router.navigate(['/dashboard']);
      })
    ).subscribe(
      (user: User) => {
        this.authService.setUser(user);
        sessionStorage.setItem('user', JSON.stringify(user));
        this.httpService.setUserToken(user.token);
        this.authService.setToken(user.token);
      },
      (error: unknown) => {
        console.error('Error logging in activated user:', error);
      }
    );
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }

  private checkIsValidActivationCode() {
    this.authService.validateActivationCode(this.activationCode, this.email).subscribe(
      (response) => {
        this.isActivationCodeValid = response.valid;
        if (this.isActivationCodeValid) {
          this.activateAccount();
        }
        this.isStatusChecked = true;
      },
      (error: unknown) => {
        console.error('Error checking activation code:', error);
        this.isStatusChecked = true
        this.isActivationCodeValid = false;
      }
    );
  }

}
