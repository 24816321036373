import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, finalize, takeUntil } from 'rxjs';
import { PaymentsService } from '../payments/payments.service';
import { StorageService } from '../storage/storage.service';
import { MoreRecords, Payment } from '../../../model/payments';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PreloadPaginationService {

  private totalPages: number;
  private currentPage: number;
  private currentAccountId: number;
  private currentOperatorId: number;
  private currentMaxIndex: number;
  private pageSize: number = 12;

  private currentPageManager: number;
  private currentOperatorIdManager: number;
  private currentMaxIndexManager: number;
  private pageSizeManager: number = 12;

  private totalPagesOperator: number;
  private currentPageOperator: number;
  private currentMaxIndexOperator: number;
  private pageSizeOperator: number = 12;

  private unsubscribe: Subject<void> = new Subject<void>();

  public loading$ = new BehaviorSubject<boolean>(false);

  public CSVProcessing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private paymentService: PaymentsService,
    private storageService: StorageService
  ) { }

  public getCurrentMaxIndex(): number {
    return this.currentMaxIndex;
  }

  public setCurrentMaxIndex(currentMaxIndex: number): void {
    this.currentMaxIndex = currentMaxIndex;
  }

  public getCurrentMaxIndexManager(): number {
    return this.currentMaxIndexManager;
  }

  public setCurrentMaxIndexManager(currentMaxIndexManager: number): void {
    this.currentMaxIndexManager = currentMaxIndexManager;
  }

  public getCurrentMaxIndexOperator(): number {
    return this.currentMaxIndexOperator;
  }

  public setCurrentMaxIndexOperator(currentMaxIndexOperator: number): void {
    this.currentMaxIndexOperator = currentMaxIndexOperator;
  }

  public getTotalPages(): number {
    return this.totalPages;
  }

  public setTotalPages(totalPages: number): void {
    this.totalPages = totalPages;
  }


  public getTotalPagesOperator(): number {
    return this.totalPagesOperator;
  }

  public setTotalPagesOperator(totalPagesOperator: number): void {
    this.totalPagesOperator = totalPagesOperator;
  }

  public getCurrentPage(): number {
    return this.currentPage;
  }

  public setCurrentPage(currentPage: number): void {
    this.currentPage = currentPage;
  }

  public getCurrentAccountId(): number {
    return this.currentAccountId;
  }

  public setCurrentAccountId(currentAccountId: number): void {
    this.currentAccountId = currentAccountId;
  }

  public getPageSize(): number {
    return this.pageSize;
  }

  public setPageSize(pageSize: number): void {
    this.pageSize = pageSize;
  }

  public getCurrentOperatorId(): number {
    return this.currentOperatorId;
  }

  public setCurrentOperatorId(currentOperatorId: number): void {
    this.currentOperatorId = currentOperatorId;
  }

  public setCurrentOperatorIdManager(currentOperatorIdManager: number): void {
    this.currentOperatorIdManager = currentOperatorIdManager;
  }

  public getCurrentOperatorIdManager(): number {
    return this.currentOperatorIdManager;
  }

  public getCurrentPageManager(): number {
    return this.currentPageManager;
  }

  public getPageSizeManager(): number {
    return this.pageSizeManager;
  }

  public setPageSizeManager(pageSizeManager: number): void {
    this.pageSizeManager = pageSizeManager;
  }

  public setCurrentPageManager(currentPageManager: number): void {
    this.currentPageManager = currentPageManager;
  }

  public getCurrentPageOperator(): number {
    return this.currentPageOperator;
  }

  public getPageSizeOperator(): number {
    return this.pageSizeOperator;
  }

  public setPageSizeOperator(pageSizeOperator: number): void {
    this.pageSizeOperator = pageSizeOperator;
  }

  public setCurrentPageOperator(currentPageOperator: number): void {
    this.currentPageOperator = currentPageOperator;
  }

  public initialCallPayments(pageSize: number) {
    this.setPageSize(pageSize);
    this.setCurrentOperatorId(0);
    this.getPaymentPages(false);
  }

  public initialCallOperatorPayments(pageSize: number, operatorId: number) {
    this.setPageSize(pageSize);
    this.setCurrentOperatorId(operatorId);
    this.getOperatorPaymentPages(false);
  }

  public callForCsv(operatorId: number) {
    this.setCurrentOperatorId(operatorId);
    if (operatorId == 0) {
      this.getPaymentPages(true);
    } else {
      this.getOperatorPaymentPages(true);
    }
  }

  public callPaymentsByPage(currentPage: number): void {
    if (this.storageService.getPaymentsByPage(currentPage + 1) == null) {
      this.callPayments(currentPage + 1);
    }
  }

  public callPaymentsByPageByOperator(currentPage: number): void {
    if (this.storageService.getPaymentsByOperatorByPage(currentPage + 1) == null) {
      this.callOperatorPayments(currentPage +1 );
    }
  }

  private callPayments(index: number) {
    if (index == 0) {
      this.loading$.next(true);
    }
    this.paymentService.getMroPaymentsByPage(index, this.pageSize)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => {if (index == 0) {this.loading$.next(false)}})
      ).subscribe(
        (payments: Payment[]) => {
          this.storageService.setPaymentsByPage(index, payments);
        }
      );
  }

  private callOperatorPayments(index: number) {
    if (index == 0) {
      this.loading$.next(true);
    }
    this.paymentService.getMroPaymentsByOperatorByPage(this.currentOperatorIdManager, index, this.pageSize)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => {if (index == 0) {this.loading$.next(false)}})
      ).subscribe(
        (payments: Payment[]) => {
          this.storageService.setPaymentsByOperatorByPage(index , payments);
        }
      );
  }

  private getAllPaymentsForCsv(pageSize: number) {
    this.CSVProcessing$.next(true);
    this.paymentService.getMroPaymentsByPage(0, pageSize)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => {this.CSVProcessing$.next(false)})
      ).subscribe(
        (payments: Payment[]) => {
          this.storageService.setPaymentsForCSV(payments);
          const blob = new Blob([this.convertToCSV(payments)], { type: 'text/csv' });
          saveAs(blob, this.generateFileName());
        }
      );
  }

  private getAllOperatorPaymentsForCsv(pageSize: number) {
    this.CSVProcessing$.next(true);
    this.paymentService.getMroPaymentsByOperatorByPage(this.currentOperatorId, 0, pageSize)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => {this.CSVProcessing$.next(false)})
      ).subscribe(
        (payments: Payment[]) => {
          this.storageService.setPaymentsForCSVByOperator(payments);
          const blob = new Blob([this.convertToCSV(payments)], { type: 'text/csv' });
          saveAs(blob, this.generateFileName());
        }
      );
  }

  private convertToCSV(data: Payment[]): string {
    const header = 'Payment Date,Operator,Payment Status,Payment Type,Check #,No. Transactions,Amount';
    const rows = data.map(payment => {
        const paymentDate = payment?.paymentDate ? `"${payment.paymentDate}"` : '';
        const operator = payment?.operator?.name ? `"${payment.operator.name}"` : '';
        const paymentStatus = payment?.status?.name ;
        const paymentType = payment?.paymentType?.name ? `"${payment.paymentType.name}"` : '';
        const checkNo = payment?.checkNo ? `"${payment.checkNo}"` : '';
        const noTransactions = payment?.royaltyPayments?.length ? `"${payment?.royaltyPayments?.length}"` : '';
        const amount = payment?.checkAmount ? `"${payment?.checkAmount}"` : '';
        return `${paymentDate},${operator},${paymentStatus},${paymentType},${checkNo},${noTransactions},${amount}`;
    });
    return `${header}\n${rows.join('\n')}`;
  }

  private generateFileName(): string {
    const date = new Date();
    const formattedDate = formatDate(date, 'yyyy-MM-dd', 'en-US');

    return `payments_${formattedDate}.csv`;
  }

  private calculateTotalPages(totalRecords: MoreRecords): number{
    return Math.round((totalRecords.noRecords + this.pageSize)/this.pageSize) > 0 ? Math.round((totalRecords.noRecords + this.pageSize)/this.pageSize) : 1;
  }

  private getOperatorPaymentPages(forCSV: boolean) {
    this.paymentService.getMroPaymentsByOperatorMoreRecords(this.currentOperatorId, 0, this.pageSize)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
        (totalRecords: MoreRecords) => {
          const totalPages = this.calculateTotalPages(totalRecords);
          if (forCSV) {
            this.getAllOperatorPaymentsForCsv(totalRecords.noRecords + this.pageSize);
          } else {
            this.setTotalPagesOperator(totalPages);
            if (totalPages < 7) {
              for (let i = 0; i < totalPages; i++) {
                setTimeout(() => {
                  this.callOperatorPayments(i);
                }, (i + 1) * 150);
              }
            } else {
              for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                  this.callOperatorPayments(i);
                }, (i + 1) * 150);
              }
              for (let i = totalPages - 3; i <= totalPages; i++) {
                setTimeout(() => {
                  this.callOperatorPayments(i);
                }, (i + 1) * 150);
              }
            }
          }
        }
      );
    }

  private getPaymentPages(forCSV: boolean) {
    this.paymentService.getMroPaymentsMoreRecords(0, this.pageSize)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
        (totalRecords: MoreRecords) => {
          const totalPages = this.calculateTotalPages(totalRecords);
            if (forCSV) {
              this.getAllPaymentsForCsv(totalRecords.noRecords + this.pageSize);
            } else {
              this.setTotalPages(totalPages);
              if (totalPages < 7) {
                for (let i = 0; i < totalPages; i++) {
                  setTimeout(() => {
                    this.callPayments(i);
                  }, (i + 1) * 150);
                }
              } else {
                for (let i = 0; i < 3; i++) {
                  setTimeout(() => {
                    this.callPayments(i);
                  }, (i + 1) * 150);
                }
                for (let i = totalPages - 3; i <= totalPages; i++) {
                  setTimeout(() => {
                    this.callPayments(i);
                  }, (i + 1) * 150);
                }
              }

            }

        }
      );
  }

}
