import { Component } from '@angular/core';
import { ContactService } from '../../core/services/contact/contact.service';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { ContactStatus } from '../../model/contact';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {

  public contactMessage: string = '';
  public messageSent: boolean = false;
  public messageError: boolean = false;
  public errorMessage: string = '';

  private unsubscribe$ = new Subject<void>();

  constructor(
    private contactService: ContactService
  ) { }

  public drawerToggle(itemID: string) {
    const drawerID = itemID + '_container';

    const item = document.getElementById(itemID);
    const drawer = document.getElementById(drawerID);

    if (item.classList.contains('btn_drawer_active')) {
      item.classList.remove('btn_drawer_active');
      drawer.classList.remove('drawer_active');
    } else {
      item.classList.add('btn_drawer_active');
      drawer.classList.add('drawer_active');
    }
  }

  public setCursorPosition(event: FocusEvent): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.selectionStart = 0;
    textarea.selectionEnd = 0;
  }

  public sendContactUs(): void {
    if (this.contactMessage.trim()) {
      const data = { body: this.contactMessage };
        this.contactService.sendContactUs(data)
          .pipe(
            takeUntil(this.unsubscribe$),
            catchError((error: unknown) => {
              console.error('Error sending contact us data:', error);
              this.messageError = true;
              this.errorMessage = 'There was an error sending your message. Please try again later.';
              return of(null);
            })
          )
          .subscribe((response: ContactStatus) => {
            if (response) {
              console.info('Contact us response:', response);
              this.messageSent = true;
            }
          });
        } else {
          console.warn('Contact message is empty');
          this.messageError = true;
          this.errorMessage = 'Please enter a message';
        }
  }

  public closeModal(): void {
    this.messageSent = false;
    this.messageError = false;
    this.contactMessage = '';
  }

}
