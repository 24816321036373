import { Component, Input } from "@angular/core";
import { StorageService } from "../../../../core/services/storage/storage.service";
import {
  GraphAndBarChartData,
  PieChartData,
} from "../../../../model/chart-data";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NoDataComponent } from "../../../no-data/no-data.component";
import { SpinnerComponent } from "../../../spinner/spinner.component";
import html2canvas from "html2canvas";
import { MatIcon } from "@angular/material/icon";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// import domtoimage from "dom-to-image";
// import { sub } from "date-fns";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-vertical-bar-chart",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    SpinnerComponent,
    NoDataComponent,
    MatIcon,
  ],
  templateUrl: "./vertical-bar-chart.component.html",
  styleUrl: "./vertical-bar-chart.component.css",
})
export class VerticalBarChartComponent {
  @Input() period?: string;
  @Input() sectionType?: string;
  @Input() isDateRange?: boolean;

  public barChartData: PieChartData[] = [];
  public animations: boolean = true;
  public showGridLines: boolean = true;
  public roundDomains: boolean = false;
  public rotateXAxisTicks: boolean = true;
  public showLegend: boolean = true;
  public legendPosition: string = "right";
  public showXAxis: boolean = true;
  public showYAxis: boolean = true;
  public chartId: number;
  private docDefinition: pdfMake.TDocumentDefinitions;

  constructor(private storageService: StorageService) {
    this.chartId = Math.floor(Math.random() * 9000) + 1000;
  }

  downloadChart(): void {
    setTimeout(() => {
      const chart = document.getElementById(
        this.chartId.toString()
      ) as HTMLElement;
      console.log("chart", chart);
      if (chart) {
        setTimeout(() => {
          html2canvas(chart, {
            height: 500,
            width: 1000,
            scale: 2,
            backgroundColor: null,
            logging: true,
            onclone: (document) => {
              document.getElementById(this.chartId.toString()).style.visibility = "visible";
            },
          }).then((canvas) => {
            const chartData = canvas.toDataURL();
            const docDefinition = {
              content: [],
              styles: {
                tableHeader: {
                  bold: true,
                  fontSize: 13,
                  color: "black",
                },
                subheader: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 10, 0, 5],
                  alignment: "left",
                },
                subsubheader: {
                  fontSize: 12,
                  bold: true,
                  margin: [0, 10, 0, 25],
                  alignment: "left",
                },
                table: {
                  margin: [0, 5, 0, 15],
                },
              },
              defaultStyle: {
                fontSize: 10,
              },
              pageOrientation: "landscape",
            };
            const title = {
              text: "Exported MRO chart",
              style: "subheader",
            };
            const description = {
              text: `Revenue by ${this.sectionType}`,
              style: "subsubheader",
            };
            docDefinition.content.push(title);
            docDefinition.content.push(description);
            docDefinition.content.push({ image: chartData, width: 500 });
            this.docDefinition = docDefinition;
            const date = new Date();
            pdfMake.createPdf(this.docDefinition).download(this.sectionType + "-" + date + "chartToPdf" + ".pdf");
          });
        });
      } else {
        console.error("Element not found: chart");
      }
    }, 0);
  }

  get isMobile() {
    return window.innerWidth <= 768;
  }

  get graphAndBarChartData() {
    if (this.dataValid) {
      return this.storageService.getMroPaymentSummaryByPeriodDataObjects();
    }
  }

  get dataValid() {
    return this.storageService.getIsMroPaymentSummaryByPeriodValid();
  }

  get loading() {
    return this.graphAndBarChartData && this.graphAndBarChartData.length <= 0;
  }

  formatDollar(val: number): string {
    return "$" + val.toLocaleString();
  }

  getGrossAmount(data: GraphAndBarChartData[]): number {
    let total = 0;
    data.forEach((month) => {
      month.series.forEach((item) => {
        total += item.value;
      });
    });
    return total;
  }

  getLegendTitle(data: GraphAndBarChartData[]): string {
    return "Gross Amount: " + this.getGrossAmount(data);
  }

  // downloadChart(): void {
  //   if (this.docDefinition) {
  //     pdfMake.createPdf(this.docDefinition).download("chartToPdf" + ".pdf");
  //   } else {
  //     console.log("No chart to download");
  //   }
  // }
  renderBarChart() {
    html2canvas(document.getElementById("barChart"), { height: 500 }).then(
      (canvas) => {
        document.body.appendChild(canvas);
      }
    );
  }
}
