<div id="mro_content">
  <div class="mobile_container">
    <div id="content_inner">
      <!-- Content Header -->
      <div class="content_header">
        <div class="content_title">
          <h1>Properties</h1>
        </div>
        <div class="content_timestamp">
          <div class="timestamp">Last updated: {{ lastModified | date: 'dd MMM yyyy h:mm a' }}</div>
        </div>
      </div>

      <!-- Action Bar -->
      <div class="content_action_bar">
        <form [formGroup]="propertyForm">
          <div class="action_bar_right">
            <ul>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_search_table">Search Table</label>
                  </div>
                  <div class="form_input">
                    <input
                      #filter_search_table
                      class="inputbox searchbox"
                      id="filter_search_table"
                      name="filter_search_table"
                      placeholder="Search Property No. or Name…"
                      (keyup)="doSearch(filter_search_table.value)"
                      formControlName="searchStr"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_property_states">Property State</label>
                  </div>
                  <div class="form_input">
                    <select
                      matTooltip="Property State"
                      name="state"
                      id="state"
                      class="inputbox"
                      formControlName="state"
                      (change)="onStateChange()"
                      title="Property State"
                    >
                      <option value="" [selected]="">All States</option>
                      <option *ngFor="let state of states" value="{{ state.id }}">
                        {{ state.name }}
                        <p *ngIf="state.abbr">({{ state.abbr }})</p>
                      </option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </form>
      </div>

      <!-- Body Content -->
      <div class="table-container">
        <table class="properties-table">
          <thead>
            <tr>
              <th class="property-name">Name</th>
              <th class="property-description">Description</th>
              <th class="property-state">State</th>
              <th class="property-county">County</th>
              <th class="property-operator">Operator</th>
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" *ngFor="let property of properties" (click)="navigateToPropertyDetail(property.id)">
              <td matTooltip="{{ property.name }}" matTooltipPosition="above">{{ property.name || '' }}</td>
              <td matTooltip="{{ property.description }}" matTooltipPosition="above">{{ property.description || '' }}</td>
              <td matTooltip="{{ property.geographicalData?.county?.state?.name }}" matTooltipPosition="above">{{ property.geographicalData?.county?.state?.abbr || '' }}</td>
              <td matTooltip="{{ property.geographicalData?.county?.name }}" matTooltipPosition="above">{{ property.geographicalData?.county?.name || '' }}</td>
              <td matTooltipPosition="above" matTooltip="{{ getUniqueOperators(property.wells).join(', ') }}">
                <ng-container *ngIf="getUniqueOperators(property.wells).length > 0">
                  {{ getUniqueOperators(property.wells).join(', ') }}
                  <div matTooltip="let operator of getUniqueOperators(property.wells)">
                    <span *ngFor="let operator of getUniqueOperators(property.wells)">

                    </span>
                  </div>
                </ng-container>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Body Footer -->
      <div class="content_footer">
        <div class="content_footer_inner">
          <div class="content_footer_left">
            <ul>
              <li>
                <button
                  type="button"
                  class="btn_normal btn_white"
                  id="btn_back"
                  (click)="onBack()"
                >
                  <span class="btn_icon"><span class="icon_back"></span></span>
                  &nbsp;<span class="btn_text">Back</span>
                </button>
              </li>
            </ul>
          </div>
          <div class="content_footer_right">
            <ul>
              <li>
                <button
                    type="button"
                    class="btn_normal btn_blue"
                    id="btn_export_csv_properties"
                    (click)="exportToCSV()"
                  >
                  <span class="btn_icon"> <img src="../assets/images/download_icon.svg" alt="Download Icon"> </span>
                    &nbsp;<span class="btn_text">CSV</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
