import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { trigger, transition, style, animate } from "@angular/animations";

import { GraphAndBarChartData } from "../../../../model/chart-data";
import { StorageService } from "../../../../core/services/storage/storage.service";
import { SpinnerComponent } from "../../../spinner/spinner.component";
import { NoDataComponent } from "../../../no-data/no-data.component";
import html2canvas from 'html2canvas';
import { MatIcon } from '@angular/material/icon';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-graph-chart",
  standalone: true,
  templateUrl: "./graph-chart.component.html",
  styleUrls: ["./graph-chart.component.css"],
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    SpinnerComponent,
    NoDataComponent,
    MatIcon
  ],
  animations: [
    trigger("animationState", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("500ms", style({ opacity: 1 })),
      ]),
      transition(":leave", [animate("500ms", style({ opacity: 0 }))]),
    ]),
  ],
})
export class GraphChartComponent {
  @Input() sectionType?: string;
  @Input() breakdown?: string;

  public view = [500, 300];
  public showXAxis: boolean = true;
  public showYAxis: boolean = true;
  public gradient: boolean = false;
  public showLegend: boolean = false;
  public legendPosition: string = "below";
  public showXAxisLabel: boolean = true;
  public showYAxisLabel: boolean = true;
  public trimXAxisTicks: boolean = true;
  public roundDomains: boolean = false;
  public showGridLines: boolean = true;
  public animations: boolean = true;
  public showRefLines: boolean = true;
  public showRefLabels: boolean = true;
  public rotateXAxisTicks: boolean = true;
  public showDataLabel: boolean = true;
  public showDataLabelOnAll: boolean = true;

  public graphAndBarChartData: GraphAndBarChartData[] = [];

  public chartId: number;

  private docDefinition: pdfMake.TDocumentDefinitions;

  constructor(private storageService: StorageService) {
    this.chartId = Math.floor(Math.random() * 9000) + 1000;
  }

  get isMobile() {
    return window.innerWidth <= 768;
  }


  get chartData() {
    switch (this.sectionType) {
      case "revenueByProduct":
        return this.storageService.getMroPaymentSummaryByPeriodByProduct() as GraphAndBarChartData[];
      case "revenueByProperty":
        return this.storageService.getMroPaymentSummaryByPeriodByProperty() as GraphAndBarChartData[];
      default:
        break;
    }
  }

  get dataValid() {
    switch (this.sectionType) {
      case "revenueByProduct":
        return this.storageService.getIsMroPaymentSummaryByPeriodByProductValid();
      case "revenueByProperty":
        return this.storageService.getIsMroPaymentSummaryByPeriodByPropertyValid();
      default:
        break;
    }
  }

  get loading() {
    return this.chartData && this.chartData.length <= 0;
  }

  get xAxisLabel() {
    switch (this.breakdown) {
      case "A":
        return "Year";
      case "M":
        return "Month";
      case "Q":
        return "Quarter";
      default:
        break;
    }
  }

  formatDollar(val: number): string {
    return '$' + val.toLocaleString();
  }

  downloadChart(): void {
    setTimeout(() => {
      const chart = document.getElementById(
        this.chartId.toString()
      ) as HTMLElement;
      console.log("chart", chart);
      if (chart) {
        setTimeout(() => {
          html2canvas(chart, {
            height: 500,
            width: 1000,
            scale: 2,
            backgroundColor: null,
            logging: true,
            onclone: (document) => {
              document.getElementById(this.chartId.toString()).style.visibility = "visible";
            },
          }).then((canvas) => {
            const chartData = canvas.toDataURL();
            const docDefinition = {
              content: [],
              styles: {
                tableHeader: {
                  bold: true,
                  fontSize: 13,
                  color: "black",
                },
                subheader: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 10, 0, 5],
                  alignment: "left",
                },
                subsubheader: {
                  fontSize: 12,
                  bold: true,
                  margin: [0, 10, 0, 25],
                  alignment: "left",
                },
                table: {
                  margin: [0, 5, 0, 15],
                },
              },
              defaultStyle: {
                fontSize: 10,
              },
              pageOrientation: "landscape",
            };
            const title = {
              text: "Exported MRO chart",
              style: "subheader",
            };
            const description = {
              text: `Revenue by ${this.sectionType}`,
              style: "subsubheader",
            };
            docDefinition.content.push(title);
            docDefinition.content.push(description);
            docDefinition.content.push({ image: chartData, width: 500 });
            this.docDefinition = docDefinition;
            const date = new Date();
            pdfMake.createPdf(this.docDefinition).download(this.sectionType + "-" + date + "chartToPdf" + ".pdf");
          });
        });
      } else {
        console.error("Element not found: chart");
      }
    }, 0);
  }
}
