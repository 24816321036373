import { HttpService } from '../../../http.service';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { EMPTY, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { User } from '../../../model/user';
import { AzureAuthService } from '../../../modules/azure-auth/services/azure-auth/azure-auth.service';
import { Router } from '@angular/router';
import { ActivationResult } from '../../../model/activation';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthService extends AuthService {

  private unsubscribe = new Subject();

  constructor(
    private httpService: HttpService,
    private azureAuthService: AzureAuthService,
    private router: Router
  ) {
    super();
  }
  registerUser(user: unknown): Observable<unknown> {
    return this.httpService.post(`/register`, user, false);
  }
  registerPortalUser(portalUser: unknown): Observable<unknown> {
    return this.httpService.post(`/registerPortalUser`, portalUser, false);
  }
  verifyToken(): Observable<unknown> {
    return this.httpService.get(`/verify`, true);
  }

  checkActiveAccount(): Observable<unknown> {
    if (!this.user) {
      return this.azureAuthService.getActiveAccount().pipe(
      takeUntil(this.unsubscribe),
      switchMap(azure => {
        if (azure) {
          sessionStorage.setItem('token', azure.idToken);
          this.loginUser(azure.account.username, azure.idToken).subscribe(
            user => {
              this.user = user;
            }
          );
        }
        return EMPTY;
      })
    );
    }
  }


  loginUser(email: string, adb2cJwtToken: string): Observable<User> {
    const body = { email: email, adb2cToken: adb2cJwtToken };
    return this.httpService.post(`/auth/mroLogin`, body, false);

  }

  logout() {
    sessionStorage.clear();
    this.user = null;
    this.token$.next(null);
    this.router.navigate(['/loggedOut']);
    this.azureAuthService.logOut();
  }

  validateActivationCode(activationCode: string, email: string): Observable<ActivationResult> {
    return this.httpService.post(`/auth/validateActivationCode`, { activationCode, email }, false);
  }

  activateMRO(email: string, adb2cJwtToken: string): Observable<unknown> {
    return this.httpService.post(`/auth/activateMRO`, { email, adb2cJwtToken }, false);
  }
}
