import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { StorageService } from '../../../../core/services/storage/storage.service';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { NoDataComponent } from '../../../no-data/no-data.component';
import html2canvas from 'html2canvas';
import { MatIcon } from '@angular/material/icon';
import { PieChartData } from '../../../../model/chart-data';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-pie-chart',
  standalone: true,
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css'],
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    SpinnerComponent,
    NoDataComponent,
    MatIcon
  ],
  encapsulation: ViewEncapsulation.None
})
export class PieChartComponent {
  @Input() period?: string;
  @Input() sectionType: string;
  @Input() isDateRange: boolean;

  public animations: boolean = true;
  public useGradient: boolean = true;
  public legend: boolean = true;
  public piechartId: number;

  private docDefinition: pdfMake.TDocumentDefinitions;

  constructor(
    private storageService: StorageService
  ) {
    this.piechartId = Math.floor(Math.random() * 9000) + 1000;
  }
  get isMobile() {
    return window.innerWidth <= 768;
  }

    get pieChartData(): PieChartData[] {
        switch (this.sectionType) {
          case 'payment':
            return this.storageService.getMroPaymentSummaryDataObjects();
          case 'property':
            return this.storageService.getMroPaymentSummaryByProperty();
          case 'product':
            return this.storageService.getMroPaymentSummaryByProduct();
        }
    }

    get dataValid() {
      switch (this.sectionType) {
        case 'payment':
          return this.storageService.getIsMroPaymentSummaryValid();
        case 'property':
          return this.storageService.getIsMroPaymentSummaryByPropertyValid();
        case 'product':
          return this.storageService.getIsMroPaymentSummaryByProductValid();
      }
    }

    get loading() {
      if (this.pieChartData) {
        return this.pieChartData.length <= 0;
      } else {
        return true;
      }
    }

    get centerValue() {
      switch (this.sectionType) {
        case 'payment':
          return this.pieChartData.reduce((sum, data) => sum + data.value, 0);
        case 'property':
          return this.pieChartData.reduce((sum, data) => sum + data.value, 0);
        case 'product':
          return this.pieChartData.reduce((sum, data) => sum + data.value, 0);
      }
    }

    get centerLabel() {
      switch (this.sectionType) {
        case 'payment':
          return 'Total Payments';
        case 'property':
          return 'Net Revenue';
        case 'product':
          return 'Net Revenue';
      }
    }

    downloadChart(): void {
      setTimeout(() => {
        const chart = document.getElementById(
          this.piechartId.toString()
        ) as HTMLElement;
        console.log("chart", chart);
        if (chart) {
          setTimeout(() => {
            html2canvas(chart, {
              height: 500,
              width: 1000,
              scale: 2,
              backgroundColor: null,
              logging: false,
              onclone: (document) => {
                document.getElementById(this.piechartId.toString()).style.visibility = "visible";
              },
            }).then((canvas) => {
              console.log("canvas", canvas);
              const chartData = canvas.toDataURL();
              const docDefinition = {
                content: [],
                styles: {
                  tableHeader: {
                    bold: true,
                    fontSize: 12,
                    color: "black",
                  },
                  subheader: {
                    fontSize: 10,
                    bold: true,
                    margin: [0, 10, 0, 5],
                    alignment: "left",
                  },
                  subsubheader: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 5],
                    alignment: "left",
                  },
                  table: {
                    margin: [0, 5, 0, 15],
                  },
                },
                defaultStyle: {
                  fontSize: 10,
                },
                pageOrientation: "landscape",
              };
              const title = {
                text: "Exported MRO chart",
                style: "subheader",
              };
              const description = {
                text: `Revenue by ${this.sectionType}`,
                style: "subsubheader",
              };
              docDefinition.content.push(title);
              docDefinition.content.push(description);
              docDefinition.content.push({ image: chartData, width: 500 });
              this.docDefinition = docDefinition;
              const date = new Date();
              pdfMake.createPdf(this.docDefinition).download(this.sectionType + "-" + date + "chartToPdf" + ".pdf");
            });
          });
        } else {
          console.error("Element not found: chart");
        }
      }, 5000);
    }

 }
