import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { users } from '../../../mockDB/users';
import { User } from '../../../model/user';
import { getRandomInt } from '../../../mockDB/helpers/randomNumbersHelper';
import { PortalUser } from '../../../model/portalUser';
import { ActivationResult } from '../../../model/activation';

@Injectable({
  providedIn: 'root'
})
export class MockAuthService extends AuthService{
  logout(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  checkActiveAccount(): Observable<unknown> {
    throw new Error('Method not implemented.');
  }

  constructor() {
    super();
  }

  private users: User[] = users;

  checkIfAdminLoggedIn(): boolean {
    return false;
  }

  getLoggedInUserRegistrationStatus(): boolean {
    return true;
  }

  loggedIn(): boolean {
    return true;
  }

  getToken(): string {
    return 'token';
  }

  getUser(): User {
    return users[getRandomInt(0, this.users.length - 1)];
  }

  registerUser(user: User): Observable<unknown> {
    console.info('MockAuthService - registerUser');
    console.info('MockAuthService - user: ', user);
    return new Observable<unknown>(observer => {
      observer.next('User registered');
      observer.complete();
    });
  }

  loginUser(email: string, _adb2cJwtToken: string): Observable<User> {
    console.info('MockAuthService - loginUser');
    console.info('MockAuthService - email: ', email);
    console.info('MockAuthService - _adb2cJwtToken: ', _adb2cJwtToken);
    return new Observable<User>(observer => {
      observer.next(users[getRandomInt(0, this.users.length - 1)]);
      observer.complete();
    });
  }

  registerPortalUser(portalUser: PortalUser): Observable<unknown> {
    console.info('MockAuthService - registerPortalUser');
    console.info('MockAuthService - portalUser: ', portalUser);
    return new Observable<unknown>(observer => {
      observer.next('Portal user registered');
      observer.complete();
    });
  }

  verifyToken(): Observable<unknown> {
    console.info('MockAuthService - verifyToken');
    return new Observable<unknown>(observer => {
      observer.next('Token verified');
      observer.complete();
    });
  }

  validateActivationCode(activationCode: string, email: string): Observable<ActivationResult> {
      console.info('MockAuthService - validateActivationCode');
      console.info('MockAuthService - activationCode: ', activationCode);
      console.info('MockAuthService - email: ', email);
      return new Observable<ActivationResult>(observer => {
          observer.next({ valid: true });
          observer.complete();
      });
  }

  activateMRO(email: string, _adb2cJwtToken: string): Observable<unknown> {
    console.info('MockAuthService - activateMRO');
    console.info('MockAuthService - email: ', email);
    console.info('MockAuthService - _adb2cJwtToken: ', _adb2cJwtToken);
    return new Observable<unknown>(observer => {
      observer.next('MRO activated');
      observer.complete();
    });
  }

}
