import { HttpService } from './../../../http.service';
import { Injectable } from '@angular/core';
import { OperatorService } from './operator.service';

@Injectable({
  providedIn: 'root'
})
export class HttpOperatorService extends OperatorService {

  constructor(
    private httpService: HttpService
  ) {
    super();
   }

  getMroPayingOperators() {
    const url = '/mro/getMroPayingOperators';
    return this.httpService.get(url, true);
  }
  
}
