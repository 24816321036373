import { Injectable } from '@angular/core';
import { OperatorService } from './operator.service';

@Injectable({
  providedIn: 'root'
})
export class MockOperatorService extends OperatorService {

  constructor() {
    super();
  }

  getMroPayingOperators() {
    return null;
  }
}
