import { wells } from './wells';
import { MroRecentActivity } from "../model/recent_activity";
import { properties } from "./properties";
import { products } from './products';

export const recentActivity1: MroRecentActivity = {
  propertyId: properties[0].id,
  propertyNo: properties[0].propertyNo,
  propertyName: properties[0].name,
  productId: products[0].id,
  productName: products[0].name,
  wellApiNo: wells[0].apiNo,
  wellName: '',
  wellStatus: wells[0].status.name,
  divisionOrderId: 0,
  salesGrossValue: 200,
  salesVolume: 50,
  paymentDate: new Date('2022-01-01'),
  ownerGrossValue: 200,
  ownerVolume: 50
};

export const recentActivity2: MroRecentActivity = {
  propertyId: properties[1].id,
  propertyNo: properties[1].propertyNo,
  propertyName: properties[1].name,
  productId: products[1].id,
  productName: products[1].name,
  wellApiNo: wells[1].apiNo,
  wellName: '',
  wellStatus: wells[1].status.name,
  divisionOrderId: 0,
  salesGrossValue: 200,
  salesVolume: 154,
  paymentDate: new Date('2022-02-01'),
  ownerGrossValue: 200,
  ownerVolume: 154
};

export const recentActivity3: MroRecentActivity = {
  propertyId: properties[2].id,
  propertyNo: properties[2].propertyNo,
  propertyName: properties[2].name,
  productId: products[2].id,
  productName: products[2].name,
  wellApiNo: wells[2].apiNo,
  wellName: '',
  wellStatus: wells[2].status.name,
  divisionOrderId: 0,
  salesGrossValue: 200,
  salesVolume: 8,
  paymentDate: new Date('2022-03-01'),
  ownerGrossValue: 200,
  ownerVolume: 8
};

export const recentActivity4: MroRecentActivity = {
  propertyId: properties[3].id,
  propertyNo: properties[3].propertyNo,
  propertyName: properties[3].name,
  productId: products[3].id,
  productName: products[3].name,
  wellApiNo: wells[3].apiNo,
  wellName: '',
  wellStatus: wells[3].status.name,
  divisionOrderId: 0,
  salesGrossValue: 200,
  salesVolume: 26,
  paymentDate: new Date('2022-04-01'),
  ownerGrossValue: 200,
  ownerVolume: 26
};

export const recentActivity5: MroRecentActivity = {
  propertyId: properties[4].id,
  propertyNo: properties[4].propertyNo,
  propertyName: properties[4].name,
  productId: products[4].id,
  productName: products[4].name,
  wellApiNo: wells[4].apiNo,
  wellName: '',
  wellStatus: wells[4].status.name,
  divisionOrderId: 0,
  salesGrossValue: 200,
  salesVolume: 66,
  paymentDate: new Date('2022-05-01'),
  ownerGrossValue: 200,
  ownerVolume: 66
};

export const recentActivities: MroRecentActivity[] = [
  recentActivity1,
  recentActivity2,
  recentActivity3,
  recentActivity4,
  recentActivity5
];
