import { Observable } from 'rxjs';
import { HttpService } from '../../../http.service';
import { ContactStatus } from './../../../model/contact';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private httpService: HttpService,
  ) { }

  sendContactUs(data: { body: string}): Observable<ContactStatus> {
    return this.httpService.post(`/sendContactUs`, data, true);
  }

}
