import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent{
  @Input() width?: string = '50px';
  @Input() height?: string = '50px';
  @Input() sectionType?: string;

  get spinnerType() {
    switch (this.sectionType) {
      case 'card':
        return {
          top: '0%',
        }
      case 'product':
        return {
          top: '50%',
        }
      case 'recentPayment':
        return {
          left: '46%',
        }
      case 'home':
        return {
          left: '46%',
          top: '50%'
        }
      case 'marketOil':
        return {
          top: '190%',
          left: '34%'
        }
      case 'marketGas':
        return {
          top: '190%',
          left: '58%'
        }
      case 'vgraph':
        return {
          top: '85% !important'
        }
      case 'payments':
        return {
          top: '30%',
          left: '45%',
        }
      default:
        return {
          top: '0%'
        };
    }
  }
}
