<div *ngIf="loading">
  <app-spinner [ngStyle]="chartType" [sectionType]="'product'"></app-spinner>
</div>
<div
  style="display: flex; justify-content: center; position: relative"
  *ngIf="!loading"
>
  <div *ngIf="dataValid" [id]="piechartId.toString()">
    <ngx-charts-pie-chart
      [animations]="animations"
      [legend]="legend"
      [legendPosition]="'left'"
      [legendTitle]="''"
      [doughnut]="true"
      [arcWidth]="0.25"
      [view]="isMobile ? [400, 300] : [500, 300]"
      [results]="pieChartData"
    >
      <ng-template #tooltipTemplate let-model="model">
        <div style="text-align: left">
          <span> {{ model.name }} ·</span>
          <span> {{ model.value | currency }}</span>
        </div>
      </ng-template>
    </ngx-charts-pie-chart>

    <div class="total-count">
      <div class="center-label" *ngIf="chartType != 'payment'">
        {{ centerLabel }}
      </div>
      <div>{{ centerValue | currency }}</div>
    </div>
  </div>

  <ng-container *ngIf="!dataValid">
    <app-no-data></app-no-data>
  </ng-container>
</div>
