<div *ngIf="!isLoggedIn && !isActivation">
  <app-login></app-login>
</div>
<div *ngIf="!isLoggedIn && isActivation">
  <app-activation></app-activation>
</div>

<div *ngIf="isLoggedIn">
  <app-token></app-token>
  <app-header></app-header>
  <router-outlet *ngIf="displayData"></router-outlet>
  <div *ngIf="!displayData" class="mro_spinner_container">
    <app-spinner [sectionType]="'home'" [width]="'100px'" [height]="'100px'"></app-spinner>
  </div>
  <app-footer></app-footer>
</div>
