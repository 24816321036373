import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PayingOperator } from '../../../model/operator';

@Injectable({
  providedIn: 'root'
})
export abstract class OperatorService {

  abstract getMroPayingOperators(): Observable<PayingOperator[]>;

}
